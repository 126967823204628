import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import RangeSlider from '../RangeSlider/RangeSlider';
import { DropdownDoubleArrow } from '../../__icons__';

import './MeteringInput.css';

const onlyDigitsRegExp = new RegExp('^(\\s?|\\d+)$');

export default class MeteringInput extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = null;
        this.input = React.createRef();

        this.state = {
            value: '',
            expanded: false
        };

        this.toggleRangeInput = this.toggleRangeInput.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleInputClick = this.handleInputClick.bind(this);
    }

    componentDidMount() {
        document.body.addEventListener('click', e => this.handleOutsideClick(e));
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', e => this.handleOutsideClick(e));
    }

    handleOutsideClick(event) {
        const { expanded } = this.state;

        if (expanded && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ expanded: false });
        }
    }

    handleChange(event) {
        const { value } = event.target;

        if (!onlyDigitsRegExp.test(value)) {
            return;
        }

        this.setState({ value });

        const { onChange } = this.props;

        if (onChange) {
            onChange(event);
        }
    }

    handleInputClick(event) {
        const { onClick } = this.props;

        if (onClick) {
            onClick(event, this.input);
        }
    }

    toggleRangeInput() {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded });
    }

    render() {
        const {
            className,
            name,
            consumptionOptions,
            placeholder,
            disabled,
            meter,
            error,
            sliderColorEnd,
            rangerSliderIcons,
            measurerIcon,
            inputValue: valueProp,
            backgroundColor,
            primaryColor,
            optionTooltip,
            divisionOptionTooltipValue,
            onChange
        } = this.props;

        const isControllerdComponent = !!onChange;

        const { value, expanded } = this.state;

        const currentValue = isControllerdComponent ? valueProp : value;

        return (
            <div
                className={classNames('ltn-de-metering-input-container', className)}
                ref={wrapperRef => (this.wrapperRef = wrapperRef)}
            >
                <div
                    className={classNames({
                        'ltn-de-metering-input': true,
                        'ltn-de-metering-input--error': error
                    })}
                    style={{ backgroundColor }}
                >
                    <div className="ltn-de-metering-input-icon" style={{ color: primaryColor }}>
                        {measurerIcon}
                    </div>
                    <input
                        ref={this.input}
                        onChange={this.handleChange}
                        value={currentValue}
                        name={name}
                        placeholder={placeholder}
                        disabled={disabled}
                        onClick={this.handleInputClick}
                    />
                    <span className="ltn-de-metering-input-meter">{meter}</span>
                    <div
                        className="ltn-de-metering-input-dropdown-icon"
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleRangeInput}
                        onKeyDown={this.toggleRangeInput}
                    >
                        <DropdownDoubleArrow />
                    </div>
                </div>
                <RangeSlider
                    name={name}
                    inputValue={currentValue}
                    onChange={this.handleChange}
                    expanded={expanded}
                    icons={rangerSliderIcons}
                    options={consumptionOptions}
                    optionTooltip={optionTooltip || meter}
                    sliderColorStart={primaryColor}
                    sliderColorEnd={sliderColorEnd}
                    primaryColor={primaryColor}
                    divisionOptionTooltipValue={divisionOptionTooltipValue}
                    min={consumptionOptions[0].value}
                    max={consumptionOptions[consumptionOptions.length - 1].value}
                />
            </div>
        );
    }
}

MeteringInput.propTypes = {
    rangerSliderIcons: PropTypes.arrayOf(PropTypes.element).isRequired,
    measurerIcon: PropTypes.element.isRequired,
    consumptionOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    meter: PropTypes.string,
    inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    sliderColorEnd: PropTypes.string,
    onClick: PropTypes.func,
    backgroundColor: PropTypes.string,
    primaryColor: PropTypes.string,
    optionTooltip: PropTypes.string,
    divisionOptionTooltipValue: PropTypes.number
};

MeteringInput.defaultProps = {
    className: '',
    disabled: false,
    error: null,
    meter: '',
    inputValue: '',
    onChange: undefined,
    name: '',
    placeholder: '',
    sliderColorEnd: undefined,
    onClick: undefined,
    backgroundColor: undefined,
    primaryColor: undefined,
    optionTooltip: undefined,
    divisionOptionTooltipValue: undefined
};
