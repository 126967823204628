import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { STYLE_GAP_SIZES } from '../../../constants';
import './ListOfFigures.css';

const LIST_OF_FIGURES_DEFAULT_CONFIG = {
    itemsGap: STYLE_GAP_SIZES.STANDARD
};

function ListOfFigures({ figures, withDescription, config }) {
    const { itemsGap = LIST_OF_FIGURES_DEFAULT_CONFIG.itemsGap } = config;

    return (
        <ul className={classNames('ltn-de-list-of-figures', `ltn-de-list-of-figures_gap_${itemsGap}`)}>
            {figures.map(figure => {
                const { id, iconLink, description } = figure;

                return (
                    <li key={id} className="ltn-de-list-of-figures__item">
                        <div className="ltn-de-list-of-figures__icon-container">
                            <img className="ltn-de-list-of-figures__icon" src={iconLink} alt={description || ''} />
                        </div>
                        {withDescription && description && (
                            <p className="ltn-de-list-of-figures__description">{description}</p>
                        )}
                    </li>
                );
            })}
        </ul>
    );
}

ListOfFigures.propTypes = {
    figures: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            iconLink: PropTypes.string.isRequired,
            description: PropTypes.string
        })
    ).isRequired,
    withDescription: PropTypes.bool,
    config: PropTypes.shape({
        itemsGap: PropTypes.oneOf([STYLE_GAP_SIZES.SMALL, STYLE_GAP_SIZES.STANDARD, STYLE_GAP_SIZES.LARGE])
    })
};

ListOfFigures.defaultProps = {
    withDescription: true,
    config: LIST_OF_FIGURES_DEFAULT_CONFIG
};

export default ListOfFigures;
