import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { DEFAULT_LOCALE } from '../../constants';

const CACHE = {};

export const getPlacePredictions = value => {
    const autocompleteService = new window.google.maps.places.AutocompleteService();

    return new Promise(resolve => {
        const request = {
            componentRestrictions: { country: DEFAULT_LOCALE },
            input: value
        };

        autocompleteService.getPlacePredictions(request, result => resolve(result));
    });
};

export const getDetails = placeId => {
    const element = document.createElement('input');
    const placesService = new window.google.maps.places.PlacesService(element);

    return new Promise(resolve => {
        const request = {
            placeId,
            fields: ['address_components']
        };

        placesService.getDetails(request, result => resolve(result));
    });
};

export const getAddressFields = addressComponents => {
    if (isEmpty(addressComponents)) {
        return null;
    }

    const cityName = addressComponents.find(el => el.types.includes('locality'));
    const houseNumber = addressComponents.find(el => el.types.includes('street_number'));
    const streetName = addressComponents.find(el => el.types.includes('route'));
    const zip = addressComponents.find(el => el.types.includes('postal_code'));

    return {
        cityName: get(cityName, 'long_name'),
        houseNumber: get(houseNumber, 'long_name'),
        streetName: get(streetName, 'short_name'),
        zip: get(zip, 'long_name')
    };
};

export async function getPossibleAddress(value) {
    if (value.length < 3) {
        return null;
    }

    if (CACHE[value]) {
        return CACHE[value];
    }

    const placeIdResult = await getPlacePredictions(value);

    if (!placeIdResult) {
        return null;
    }

    const placeId = placeIdResult[0].place_id;
    const placeDetails = await getDetails(placeId);

    const addressComponents = placeDetails.address_components;
    const address = getAddressFields(addressComponents);

    CACHE[value] = address;
    return address;
}
