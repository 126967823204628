import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ListOfFigures from '../../__atoms__/ListOfFigures';
import { STYLE_GAP_SIZES, PRODUCT_CERTIFICATE_NAMES } from '../../../constants';
import './ProductAdvantagesSection.css';
import { DEFAULT_PRODUCT_CERTIFICATES_DATA } from './constants';

const PRODUCT_ADVANTAGES_SECTION_DEFAULT_CONFIG = {
    advantagesGap: STYLE_GAP_SIZES.STANDARD
};

function ProductAdvantagesSection({
    advantages,
    showCertificates,
    certificates,
    productCertificatesData,
    className,
    config
}) {
    const itemsGap = config.advantagesGap;

    return (
        <div className={classNames('ltn-de-product-advantages-section', className)}>
            <div className="ltn-de-product-advantages-section__advantages">
                <ListOfFigures figures={advantages} config={{ itemsGap }} />
            </div>
            {showCertificates && certificates && (
                <div className="ltn-de-product-advantages-section__certificates">
                    <div className="ltn-de-product-advantages-section__certificates-container">
                        {certificates.map(certificate => {
                            const certificateData = productCertificatesData[certificate];

                            if (!certificateData) {
                                return null;
                            }

                            return (
                                <img
                                    key={certificate}
                                    className="ltn-de-product-advantages-section__certificate-image"
                                    src={certificateData.iconLink}
                                    alt={certificateData.label}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

ProductAdvantagesSection.propTypes = {
    className: PropTypes.string,
    advantages: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            iconLink: PropTypes.string.isRequired,
            description: PropTypes.string
        })
    ).isRequired,
    showCertificates: PropTypes.bool,
    certificates: PropTypes.arrayOf(
        PropTypes.oneOf([PRODUCT_CERTIFICATE_NAMES.OK_POWER, PRODUCT_CERTIFICATE_NAMES.TUV_NORD])
    ),
    productCertificatesData: PropTypes.objectOf(
        PropTypes.shape({
            iconLink: PropTypes.string,
            label: PropTypes.string
        })
    ),
    config: PropTypes.shape({
        advantagesGap: PropTypes.oneOf([STYLE_GAP_SIZES.SMALL, STYLE_GAP_SIZES.STANDARD, STYLE_GAP_SIZES.LARGE])
    })
};

ProductAdvantagesSection.defaultProps = {
    className: undefined,
    showCertificates: true,
    certificates: [PRODUCT_CERTIFICATE_NAMES.OK_POWER, PRODUCT_CERTIFICATE_NAMES.TUV_NORD],
    productCertificatesData: DEFAULT_PRODUCT_CERTIFICATES_DATA,
    config: PRODUCT_ADVANTAGES_SECTION_DEFAULT_CONFIG
};

export default ProductAdvantagesSection;
