import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { LocationIcon } from '../../__icons__';
import { GOOGLE_MAPS_API_KEY, DEFAULT_LOCALE } from '../../../constants';
import './LocationInputV2.css';

import { getAddressFields } from '../../../services/helpers/places';
import { AppContext } from '../../../services/helpers/context';

// TODO: Add more test
export default class LocationInputV2 extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.autocomplete = null;

        this.autocompleteInput = React.createRef();

        this.handleSelectPlace = this.handleSelectPlace.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.initSelectAutocomplete = this.initSelectAutocomplete.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // TODO: Rename to ShouldLoadGoogleScript
        const { isGoogleScriptLoaded } = this.props;

        if (!isGoogleScriptLoaded) {
            this.loadGooglePlaceScript();
        }
    }

    componentWillUnmount() {
        if (this.autocomplete && this.autocomplete.removeListener) {
            this.autocomplete.removeListener('place_changed');
        }
    }

    handleSelectPlace() {
        if (!this.autocomplete || !this.autocomplete.getPlace) {
            return;
        }

        const addressObject = this.autocomplete.getPlace();
        const addressComponents = get(addressObject, 'address_components');
        const address = getAddressFields(addressComponents);

        const { onAddressChange } = this.props;
        onAddressChange(address);
    }

    handleChange(event) {
        const { onChange, onAddressChange } = this.props;

        onAddressChange();

        if (onChange) {
            onChange(event);
        }
    }

    handleClick(event) {
        const { onClick } = this.props;

        if (onClick) {
            onClick(event, this.autocompleteInput);
        }
    }

    loadGooglePlaceScript() {
        const { locale, apiKey } = this.props;
        const loadedScript = document.getElementById('googleAutoCompleteLibrary');

        if (!loadedScript) {
            const script = document.createElement('script');
            script.id = 'googleAutoCompleteLibrary';
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=${locale}`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        }
    }

    initSelectAutocomplete() {
        if (window.google && !this.autocomplete) {
            const options = {
                types: ['address'],
                componentRestrictions: { country: DEFAULT_LOCALE },
                fields: ['address_components']
            };

            this.autocomplete = new window.google.maps.places.Autocomplete(this.autocompleteInput.current, options);
            this.autocomplete.addListener('place_changed', this.handleSelectPlace);
        }
    }

    render() {
        const {
            className,
            placeholder,
            disabled,
            error,
            value,
            name,
            autoFocus,
            isGoogleScriptLoaded,
            backgroundColor,
            primaryColor
        } = this.props;

        const classes = classNames({
            'ltn-de-site-location-input': true,
            'ltn-de-site-location--disabled': disabled,
            'ltn-de-site-location--error': error,
            [className]: !!className
        });

        const { isAppGoogleScriptLoaded } = this.context;

        if (isGoogleScriptLoaded && !isAppGoogleScriptLoaded) {
            return null;
        }

        return (
            <div className={classes} style={{ backgroundColor }}>
                <div className="ltn-de-site-type-icon" style={{ color: primaryColor }}>
                    <LocationIcon />
                </div>
                <input
                    ref={this.autocompleteInput}
                    type="text"
                    name={name}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    disabled={disabled}
                    defaultValue={value}
                    onClick={this.handleClick}
                    onChange={this.handleChange}
                    onFocus={this.initSelectAutocomplete}
                />
            </div>
        );
    }
}

LocationInputV2.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    error: PropTypes.string,
    onAddressChange: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    value: PropTypes.string,
    locale: PropTypes.string,
    apiKey: PropTypes.string,
    onChange: PropTypes.func,
    isGoogleScriptLoaded: PropTypes.bool,
    backgroundColor: PropTypes.string,
    primaryColor: PropTypes.string
};

LocationInputV2.defaultProps = {
    className: '',
    placeholder: '',
    name: undefined,
    disabled: false,
    autoFocus: undefined,
    error: null,
    onClick: undefined,
    value: '',
    locale: DEFAULT_LOCALE,
    apiKey: GOOGLE_MAPS_API_KEY,
    onChange: undefined,
    isGoogleScriptLoaded: true,
    backgroundColor: undefined,
    primaryColor: undefined
};
