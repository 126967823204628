import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { arrowUp, arrowDown } from '../../__icons__/ArrowDownAndUp';

import './Collapse2.css';

// TODO: Use instead Collapse component
// TODO: add defaultExpanded prop to set only default state, but component is uncontrolled
export default class Collapse extends Component {
    constructor(props) {
        super(props);
        this.handelChange = this.handelChange.bind(this);

        this.state = {
            isActiveState: props.isActive || false
        };
    }

    componentDidUpdate(prevProps) {
        const { isActive, onExit } = this.props;

        if (onExit && prevProps.isActive && !isActive) {
            onExit();
        }
    }

    handelChange() {
        const { onChange, id } = this.props;

        if (onChange) {
            onChange(id);
            return;
        }

        const { isActiveState } = this.state;
        this.setState({ isActiveState: !isActiveState });
    }

    render() {
        const { label, children, className, contentClassName, openIcon, closeIcon, additionalSetting, isActive } =
            this.props;

        const { isActiveState } = this.state;
        const isControlledComponent = isActive !== undefined;
        const isActiveCollapse = isControlledComponent ? isActive : isActiveState;

        return (
            <div className={classNames('ltn-de-collapse2-container', className)}>
                <button className="ltn-de-collapse2" type="button" onClick={this.handelChange}>
                    <span className="ltn-de-collapse2-label">{label}</span>
                    <div className="ltn-de-collapse2-settings">
                        {additionalSetting && additionalSetting}
                        <span className="ltn-de-collapse2-arrow">{isActiveCollapse ? closeIcon : openIcon}</span>
                    </div>
                </button>
                {isActiveCollapse && (
                    <div className={classNames('ltn-de-collapse2-children', contentClassName)}>{children}</div>
                )}
            </div>
        );
    }
}

Collapse.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    additionalSetting: PropTypes.element,
    openIcon: PropTypes.element,
    closeIcon: PropTypes.element,
    isActive: PropTypes.bool,
    onChange: PropTypes.func,
    id: PropTypes.string,
    onExit: PropTypes.func
};

Collapse.defaultProps = {
    label: '',
    className: null,
    contentClassName: null,
    additionalSetting: null,
    openIcon: arrowDown,
    closeIcon: arrowUp,
    isActive: undefined,
    onChange: undefined,
    id: '',
    onExit: undefined
};
