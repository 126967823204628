import { PRODUCT_CERTIFICATE_NAMES } from '../../../constants';

export const DEFAULT_PRODUCT_CERTIFICATES_DATA = {
    [PRODUCT_CERTIFICATE_NAMES.OK_POWER]: {
        iconLink:
            '//images.ctfassets.net/4gjysvsltb3v/6pNBJlJ8LfqKiLX7B3wIsc/efedd9e61500091f901f6b0058aa4284/group-78.svg',
        label: 'OK Power certificate'
    },
    [PRODUCT_CERTIFICATE_NAMES.TUV_NORD]: {
        iconLink:
            '//images.ctfassets.net/4gjysvsltb3v/3R8zFTtExw1MPguVMKSuzL/d7a93c498f165a5a4011ddab30e59158/group-157.svg',
        label: 'TUV Nord certificate'
    }
};
