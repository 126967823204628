import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './RangeSlider.css';

export default class RangeSlider extends React.Component {
    constructor(props) {
        super(props);
        const { options, inputValue } = props;

        this.state = {
            currentValue: inputValue || options[0].value
        };

        this.sliderEffectRef = React.createRef();
        this.sliderTooltipRef = React.createRef();
        this.sliderThumbRef = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.controlThumbPosition = this.controlThumbPosition.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
    }

    componentDidMount() {
        const { currentValue } = this.state;
        this.controlThumbPosition(currentValue);
    }

    componentDidUpdate(prevProps) {
        const { inputValue, divisionOptionTooltipValue, options } = this.props;

        if (
            prevProps.inputValue !== inputValue ||
            prevProps.divisionOptionTooltipValue !== divisionOptionTooltipValue ||
            prevProps.options[0].value !== options[0].value
        ) {
            this.controlThumbPosition(inputValue || options[0].value);
        }
    }

    handleChange(event) {
        const {
            target: { value }
        } = event;

        this.controlThumbPosition(value);

        const { onChange } = this.props;

        if (onChange) {
            onChange(event);
        }
    }

    handleClick(value) {
        this.controlThumbPosition(value);

        const { onChange, name } = this.props;

        if (onChange) {
            onChange({ target: { value, name } });
        }
    }

    controlThumbPosition(currentValue) {
        const { sliderColorStart, sliderColorEnd, min, max } = this.props;
        const ratioTemp = ((currentValue - min) * 100) / (max - min);

        let ratio = ratioTemp;

        if (ratioTemp < 0) {
            ratio = 0;
        }

        if (ratioTemp > 100) {
            ratio = 100;
        }

        this.sliderEffectRef.current.style.background =
            `linear-gradient(to right, ${sliderColorStart} 0%,` +
            `${sliderColorStart} ${ratio}%,` +
            `${sliderColorEnd} ${ratio}%,` +
            `${sliderColorEnd} 100%)`;

        this.sliderTooltipRef.current.style.left = `${ratio}%`;
        this.sliderThumbRef.current.style.left = `${ratio}%`;

        this.setState({ currentValue });
    }

    renderOptions() {
        const { options, icons } = this.props;

        return options.map((option, index) => (
            <div
                className="ltn-de-site-rangeslider-option"
                role="button"
                key={`${index + option}`}
                tabIndex={0}
                onClick={() => this.handleClick(option.value)}
                onKeyDown={() => this.handleClick(option.value)}
            >
                <span className="ltn-de-site-rangeslider-option-icon">
                    {icons[index]}
                    {option.label ? (
                        <span className="ltn-de-site-rangeslider-option-icon-label">{option.label}</span>
                    ) : null}
                </span>
            </div>
        ));
    }

    renderTooltip() {
        const { optionTooltip, primaryColor, divisionOptionTooltipValue } = this.props;
        const { currentValue } = this.state;

        const value = divisionOptionTooltipValue ? Math.round(currentValue / divisionOptionTooltipValue) : currentValue;

        return (
            <div
                className="ltn-de-site-rangeslider-tooltip"
                ref={this.sliderTooltipRef}
                style={{ backgroundColor: primaryColor, borderColor: primaryColor }}
            >
                <p>{`${value} ${optionTooltip}`}</p>
            </div>
        );
    }

    render() {
        const { className, stepCount, expanded, name, primaryColor, min, max } = this.props;
        const { currentValue } = this.state;

        const rangeSliderClasses = classNames({
            'ltn-de-site-rangeslider': true,
            'ltn-de-site-rangeslider--active': expanded,
            [className]: !!className
        });

        return (
            <div className={rangeSliderClasses}>
                <div className="ltn-de-site-rangeslider-tooltip-container">
                    {this.renderTooltip()}
                    <div
                        className="ltn-de-site-rangeslider-panel__thumb"
                        ref={this.sliderThumbRef}
                        style={{ backgroundColor: primaryColor }}
                    />
                </div>
                <input
                    ref={this.sliderEffectRef}
                    value={currentValue}
                    type="range"
                    name={name}
                    min={min}
                    max={max}
                    step={stepCount}
                    className={classNames(
                        'ltn-de-site-rangeslider-panel',
                        primaryColor && 'ltn-de-site-rangeslider-panel_colored'
                    )}
                    style={{ backgroundColor: primaryColor }}
                    onChange={this.handleChange}
                />
                <div className="ltn-de-site-rangeslider-options">{this.renderOptions()}</div>
            </div>
        );
    }
}

RangeSlider.propTypes = {
    icons: PropTypes.arrayOf(PropTypes.element).isRequired,
    optionTooltip: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })).isRequired,
    min: PropTypes.string.isRequired,
    max: PropTypes.string.isRequired,
    name: PropTypes.string,
    inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    stepCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    expanded: PropTypes.bool,
    onChange: PropTypes.func,
    sliderColorStart: PropTypes.string,
    sliderColorEnd: PropTypes.string,
    primaryColor: PropTypes.string,
    divisionOptionTooltipValue: PropTypes.number
};

RangeSlider.defaultProps = {
    name: '',
    inputValue: '',
    className: '',
    stepCount: 1,
    expanded: false,
    onChange: undefined,
    sliderColorStart: '#b6edc8',
    sliderColorEnd: '#f4f4f4',
    primaryColor: undefined,
    divisionOptionTooltipValue: undefined
};
