import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowDownAndUp({ transformValue }) {
    return (
        <svg height="16" width="20" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
                stroke="black"
                strokeWidth="2"
                d="M21.698,15.286l-9.002-8.999  c-0.395-0.394-1.035-0.394-1.431,0c-0.395,0.394-0.395,1.034,0,1.428L19.553,16l-8.287,8.285c-0.395,0.394-0.395,1.034,0,1.429  c0.395,0.394,1.036,0.394,1.431,0l9.002-8.999C22.088,16.325,22.088,15.675,21.698,15.286z"
                transform={transformValue}
            />
        </svg>
    );
}

export const arrowUp = <ArrowDownAndUp transformValue="rotate(270, 10, 16)" />;
export const arrowDown = <ArrowDownAndUp transformValue="rotate(90, 16, 10)" />;

ArrowDownAndUp.propTypes = {
    transformValue: PropTypes.string.isRequired
};
